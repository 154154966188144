import React from 'react';
import '../styles/Booking.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
const Booking = () => {
    const location = useLocation();
    const { booking_id } = location.state || {};

    return (
        <div className="booking-container">
            <div className="booking-content">
                <img src="/booking.gif" alt="Booking confirmed" className="booking-gif" />
                <h1 className="booking-title">Booking Confirmed! 🎉</h1>
                {booking_id ? (
                    <p className="booking-text">
                        Your move is set and booking ID is: <strong>{booking_id}</strong>.<br/>
                        We will connect you with a friendly local soon🛠.<br/>
                        Check your email for more details.<br/>
                        <br/> {/* Additional space for clarity */}
                        Want to start a new booking? <a href="https://www.moveflair.com" className="booking-link">Click here</a> to visit our website.
                    </p>
                ) : (
                    <p className="booking-error">No booking ID found. Please try again.</p>
                )}
            </div>
        </div>
    );
};

export default Booking;
