import { calculateMoversOnlyPrice, calculateMoversAndDriversPrice, calculateDriversOnlyPrice } from '../services/MoveFlairPricing';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from '../components/ProgressSteps/ProgressBar';
import {useDispatch, useSelector} from 'react-redux';
import { setSchedule } from '../features/moveSlice';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

const Schedule = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [selectedHours, setSelectedHours] = useState(0);
    const [selectedMinutes, setSelectedMinutes] = useState(30); // Default to 30 mins for 0 hours
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const savedSchedule = useSelector((state) => state.move.schedule);
    const savedDuration = useSelector((state) => state.move.duration);
    const { serviceType, numMovers } = useSelector((state) => state.move.serviceDetails);
    const distance = useSelector((state) => state.move.distance);

    useEffect(() => {
        if (savedSchedule) {
            setStartDate(new Date(savedSchedule));
        }
        if (savedDuration) {
            setSelectedHours(savedDuration.hours);
            setSelectedMinutes(savedDuration.minutes || (savedDuration.hours === 0 ? 30 : 0)); // If no minutes, default to 30 or 0
        }
    }, [savedSchedule, savedDuration]);

    const handleDateChange = (date) => {
        setStartDate(date);
    };


    const handleNext = () => {
        if (startDate) {
            // Calculate the total minutes selected
            const totalMinutes = (selectedHours * 60) + selectedMinutes;

            let estimatedCost = 0;

            // Calculate the price based on service type
            if (serviceType === 'movers' ) {
                estimatedCost = calculateMoversOnlyPrice(totalMinutes, numMovers);
            } else if (serviceType === 'moverDrives') {
                estimatedCost = calculateMoversAndDriversPrice(totalMinutes, distance, 1.60, 0, numMovers);
            } else if (serviceType === 'drivers') {
                estimatedCost = calculateDriversOnlyPrice(totalMinutes, distance, 1.60);
            }

            // Save the schedule, estimated cost, and duration in the Redux store
            dispatch(setSchedule({
                date: startDate,
                estimatedCost,
                duration: { hours: selectedHours, minutes: selectedMinutes }
            }));

            setLoading(true);
            setTimeout(() => {
                navigate('/review');
            }, 2000);
        } else {
            alert('Please select a date and time.');
        }
    };

    const handlePrevious = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/items');
        }, 2000);
    };

    // Handle hour and minute changes
    const handleHourChange = (e) => {
        const hours = parseInt(e.target.value);
        setSelectedHours(hours);

        // Adjust minutes if 0 hours is selected
        if (hours === 0) {
            setSelectedMinutes(30); // Default to 30 mins if 0 hours is selected
        } else if (selectedMinutes === 30 || selectedMinutes === 45)  {
            setSelectedMinutes(0); // Default to 00 mins if 1 hour or more is selected
        }
    };

    const handleMinuteChange = (e) => {
        setSelectedMinutes(parseInt(e.target.value));
    };

    return (
        <div className="p-5">
            <ProgressBar step="schedule" />
            <h1 className="text-3xl font-bold mb-5 text-center">Schedule Your Move</h1>
            <div className="flex justify-center items-center">
                <div className="space-y-5 w-full max-w-md">
                    <div>
                        <label className="block text-gray-700 text-center">Select Date and Time</label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            showTimeSelect
                            dateFormat="Pp"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Add duration selection form here */}
                    <div className="mt-5">
                        <label className="block text-gray-700 text-center">How long do you expect to need this service?</label>
                        <div className="flex justify-center mt-2">
                            {/* Hour selection */}
                            <select
                                value={selectedHours}
                                onChange={handleHourChange}
                                className="border border-gray-300 p-2 rounded mr-2"
                            >
                                <option value={0}>0 hour</option>
                                <option value={1}>1 hour</option>
                                <option value={2}>2 hours</option>
                                <option value={3}>3 hours</option>
                                <option value={4}>4 hours</option>
                            </select>

                            {/* Minute selection */}
                            <select
                                value={selectedMinutes}
                                onChange={handleMinuteChange}
                                className="border border-gray-300 p-2 rounded"
                            >
                                {selectedHours === 0 ? (
                                    <>
                                        <option value={30}>30 minutes</option>
                                        <option value={45}>45 minutes</option>
                                    </>
                                ) : (
                                    <>
                                        <option value={0}>00 minutes</option>
                                        <option value={15}>15 minutes</option>
                                        <option value={30}>30 minutes</option>
                                        <option value={45}>45 minutes</option>
                                    </>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-between mt-5">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Loading...</p>
                </div>
            )}
        </div>
    );
};

export default Schedule;
