// src/pages/NotFound.js
import React from 'react';
import '../styles/404.css'; // We will create this CSS file next

const NotFound = () => {
    return (
        <div className="not-found-container">
            <img src="/moveflair-logo.gif" alt="MoveFlair Logo" className="logo" /> {/* Ensure the logo path is correct */}
            <h1>Oops! It looks like you've packed up the wrong page!</h1>
            <p>We can't seem to find what you're looking for.</p>
            <p>Don't worry, moving can be tricky! Why not start over and get your booking back on track?</p>
            <a href="http://www.moveflair.com" className="btn">Restart Your Booking</a>
            <div className="footer">
                If you keep getting lost, feel free to contact our support team at <a href="mailto:support@moveflair.com">support@moveflair.com</a>.
            </div>
        </div>
    );
};

export default NotFound;