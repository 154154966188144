import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { calculateMoversOnlyPrice, calculateMoversAndDriversPrice, calculateDriversOnlyPrice } from '../../services/MoveFlairPricing'; // Import the pricing functions

const TimeSlider = ({ serviceType, numMovers, closeModal }) => {
    const [time, setTime] = useState(30); // Default time in minutes (30 mins)
    const distance = useSelector((state) => state.move.distance); // Get the distance from the Redux state
    const [estimatedCost, setEstimatedCost] = useState(0);
    const fuelCostPerKm = 1.60; // Example fuel cost per km, could come from user input
    const truckRentalCost = 0; // Optional truck rental cost

    useEffect(() => {
        let cost = 0;

        if (serviceType === 'moverDrives') {
            // Driver & Mover service with optional 1 or 2 movers
            cost = calculateMoversAndDriversPrice(time, distance, fuelCostPerKm, truckRentalCost, numMovers);
        } else if (serviceType === 'movers') {
            // Just a Mover service with optional 1 or 2 movers
            cost = calculateMoversOnlyPrice(time, numMovers); // Pass numMovers for Just a Mover
        } else if (serviceType === 'drivers') {
            // Driver Only service
            cost = calculateDriversOnlyPrice(time, distance);
        }

        setEstimatedCost(cost); // Update estimated cost
    }, [time, distance, serviceType, numMovers]);

    return (
        <div>
            {
                serviceType !== 'moverDrives' ? (
                    <h3 className="text-lg font-bold mb-2">Estimated Cost for {serviceType}</h3>
                ) : (
                    <h3 className="text-lg font-bold mb-2">Estimated Cost for Move and Drive service</h3>
                )
            }            <p className="text-sm mb-4 text-gray-500">Adjust the slider to estimate the time (between 30 mins to 4 hours).</p>

            {/* Time Slider */}
            <div className="flex items-center mb-4">
                <span className="mr-2 text-gray-700">30 mins</span>
                <input
                    type="range"
                    min="30"
                    max="240"
                    step="15"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className="w-full"
                />
                <span className="ml-2 text-gray-700">4 hours</span>
            </div>

            <div className="flex justify-between items-center mb-4">
                <span className="text-lg font-semibold">Selected Time: {time} minutes</span>
                <span className="text-lg font-semibold">Estimated Cost: <br/><s>${estimatedCost.toFixed(2)}</s> <span className="text-green-700">${(estimatedCost/2).toFixed(2)}</span></span>
            </div>

            <div className="mb-4">
                {(serviceType === 'moverDrives' || serviceType === 'drivers') && (
                    <>

                        <p className="text-sm text-gray-500">Distance: {distance.toFixed(2)} km</p>
                        <p className="text-sm text-gray-500">Fuel Cost is included in estimate</p>
                        <p className="text-sm text-gray-500">Truck Rental(Optional and not included in estimated cost): $19.5 + 0.8/km</p>
                        <p className="text-sm text-white bg-orange-400 p-2 rounded">
                            Large items require a truck rental. You may provide your own, rent one or MoveFlair rents for you at an additional cost. Contact info@moveflair.com .
                        </p>                    </>
                )}
            </div>

            {/* Close Modal Button */}
         <div style={{ display: 'flex', justifyContent: 'center' }}>
  <button
    className="bg-red-500 text-white mb-5 py-1 px-4 rounded hover:bg-red-700 transition-colors duration-300"
    onClick={closeModal}
  >
    Close
  </button>
</div>

        </div>
    );
};

export default TimeSlider;