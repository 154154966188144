import React from 'react';

const ProgressBar = ({ step }) => {
    const steps = ['service', 'items', 'schedule', 'review'];
    return (
        <div className="relative flex justify-between items-center mb-5">
            {steps.map((item, index) => (
                <React.Fragment key={item}>
                    {index !== 0 && (
                        <div className="flex-grow h-0.5 bg-gray-300"></div>
                    )}
                    <div className="relative flex flex-col items-center">
                        <div
                            className={`w-8 h-8 flex items-center justify-center rounded-full ${
                                step === item ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                        >
                            <span className={`text-white ${step === item ? 'text-black' : 'text-white'}`}>
                                ●
                            </span>
                        </div>
                        <span
                            className={`mt-2 text-sm font-semibold ${
                                step === item ? 'text-blue-500' : 'text-gray-500'
                            }`}
                        >
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                        </span>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProgressBar;