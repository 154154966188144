import React from 'react';
import '../styles/Header.css';

const Header = () => (
    <header>
        <nav>
            <div className="nav-left">
                <img src='/moveflair-logo.png' alt="Moveflair Logo" className="logo-image" />

                <div className="logo-text">Moveflair</div>
            </div>
        </nav>
    </header>
);

export default Header;
