export function calculateMoversOnlyPrice(minutes, numMovers = 1) {
    const BASE_RATE_MOVERS_ONLY = 5000; // 5000 cents ($50) for the first hour
    const HOURLY_RATE_MOVERS_ONLY = 3500; // 3500 cents ($35) per hour after the first hour
    const BASE_RATE_MINUTES = 60;

    let cost;

    if (minutes <= BASE_RATE_MINUTES) {
        cost = BASE_RATE_MOVERS_ONLY * numMovers; // Multiply base rate by number of movers
    } else {
        let extraMinutes = minutes - BASE_RATE_MINUTES;
        cost = (BASE_RATE_MOVERS_ONLY + (extraMinutes * HOURLY_RATE_MOVERS_ONLY) / BASE_RATE_MINUTES) * numMovers;
    }

    const totalCost = cost / 100; // Convert from cents to dollars

    return totalCost;
}
export function calculateMoversAndDriversPrice(minutes, distanceKm, fuelCostPerKm = 1.75, optionalTruckRentalCost = 0, numMovers = 2) {
    const RATE_PER_MINUTE_MOVERS_DRIVERS = 100; // 100 cents per minute per mover (i.e., $60/hour per mover)
    const MILEAGE_RATE_MOVERS_DRIVERS = 200; // 200 cents per km after the first 10 km (accounting for fuel and vehicle wear)
    const DISTANCE_THRESHOLD = 10.0; // First 10 km are free, charge applies after

    // Time-based cost (per mover)
    let timeCost = minutes * RATE_PER_MINUTE_MOVERS_DRIVERS * numMovers; // Time cost for multiple movers
    let mileageCost = (distanceKm > DISTANCE_THRESHOLD)
        ? (distanceKm - DISTANCE_THRESHOLD) * MILEAGE_RATE_MOVERS_DRIVERS
        : 0; // Mileage cost if distance exceeds 10 km
    let fuelCost = distanceKm * fuelCostPerKm * 100; // Fuel cost in cents
    let truckRentalCost = optionalTruckRentalCost * 100; // Truck rental cost in cents

    // Total cost in cents converted to dollars
    const totalCost = (timeCost + mileageCost + fuelCost + truckRentalCost) / 100;

    return totalCost;
}
export function calculateDriversOnlyPrice(minutes, distanceKm, fuelCostPerKm = 1.75) {
    const RATE_PER_MINUTE_DRIVERS_ONLY = 50; // 50 cents per minute (i.e., $30/hour)
    const MILEAGE_RATE_DRIVERS_ONLY = 200; // 200 cents per km after 10 km (adjusted for vehicle wear and tear)
    const DISTANCE_THRESHOLD = 10.0; // First 10 km are free, charge applies after

    let timeCost = minutes * RATE_PER_MINUTE_DRIVERS_ONLY; // Time-based cost for the driver
    let mileageCost = (distanceKm > DISTANCE_THRESHOLD)
        ? (distanceKm - DISTANCE_THRESHOLD) * MILEAGE_RATE_DRIVERS_ONLY
        : 0; // Extra mileage cost if distance exceeds threshold
    let fuelCost = distanceKm * fuelCostPerKm * 100; // Fuel cost in cents

    const totalCost = (timeCost + mileageCost + fuelCost) / 100; // Convert from cents to dollars
    console.log("Total Estimated Cost for Driver Only (dollars):", totalCost);
    return totalCost;
}