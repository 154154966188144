import React from 'react';
import '../styles/Home.css';
import SelectService from './HomeComponents/SelectService';
import { useDispatch } from 'react-redux';
import { setServiceDetails } from '../features/moveSlice';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
const Home = () => {
    const dispatch = useDispatch();
    const handleServiceSelection = (service) => {
        dispatch(setServiceDetails(service)); // Save the service details to Redux store
    };
    return (
        <div className="home-container">

                <SelectService onServiceSelect={handleServiceSelection} />
        </div>
    );
};

export default Home;