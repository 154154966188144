import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fromAddress: '',
  toAddress: '',
  distance: 0,
  serviceDetails: null,
  items: [],
  schedule: null,
  estimatedCost: 0, // Add estimatedCost to the initial state
  duration: { hours: 0, minutes: 0 }, // Add duration to the initial state
};

const moveSlice = createSlice({
  name: 'move',
  initialState,
  reducers: {
    setFromAddress(state, action) {
      state.fromAddress = action.payload;
    },
    setToAddress(state, action) {
      state.toAddress = action.payload;
    },
    setDistance(state, action) {
      state.distance = action.payload;
    },
    setServiceDetails(state, action) {
      state.serviceDetails = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    setSchedule(state, action) {
      state.schedule = action.payload.date;
      state.estimatedCost = action.payload.estimatedCost; // Update estimatedCost
      state.duration = action.payload.duration; // Update duration with hours and minutes
    },
  },
});

export const { setFromAddress, setToAddress, setDistance, setServiceDetails, setItems, setSchedule } = moveSlice.actions;
export default moveSlice.reducer;
