// CitiesModal.js
import React from 'react';
import '../../styles/CitiesModal.css';

const CitiesModal = ({ show, handleClose }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Planned Cities</h2>
                <ul className="text-decoration-none">
                    <li>Greater Toronto Area</li>
                    <li>London</li>
                    <li>Brantford region</li>
                </ul>
                <button onClick={handleClose} className="close-button">Close</button>
            </div>
        </div>
    );
};

export default CitiesModal;